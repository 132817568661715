/*Header */
/*scroll header*/
if ($('.page-template-tmpl_contact').length || $('.page-template-tmpl_booking').length) {
  $('.header_wrapper').addClass('scrolled');
} else {
  $(window).scroll(function() {
    if ($(window).scrollTop() > 80) {
      $('.header_wrapper').addClass('scrolled');
    } else {
      $('.header_wrapper').removeClass('scrolled');
    }
  });
}

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})

/*Open Menu*/
$(".menu_open button").on('click', function() {
    if($(this).hasClass('is-active')){
        $("body").css('overflow-y','scroll');
        $(this).removeClass('is-active');
        $('.headermenu').removeClass('is-active');
        $('.header_wrapper').removeClass('openmenu');
        $('.menu li').removeClass('aos-animate');
      }
      else{
        $(this).addClass('is-active');
        jQuery("body").css('overflow-y','hidden');
        $('.headermenu').addClass('is-active');
        $('.header_wrapper').addClass('openmenu');
        setTimeout(
        function()
        {
          $( ".menu li" ).each(function( index ) {
                    $(this).addClass('aos-animate');
                    $(this).addClass('aos-init');
          });
      }, 1000);
    }
});


/*Menu Animation*/
// $( document ).ready(function() {
//   $( ".menu li" ).each(function( index ) {
//       $(this).attr('data-aos','fade-up');
//       $(this).attr('data-aos-duration','600');
//       $(this).addClass('aos-init');
//       setTimeout(function() {$( ".menu li" ).removeClass('aos-animate');  }, 300);
//       if(index==0){
//           $(this).attr('data-aos-delay',100);
//       }else{
//         $(this).attr('data-aos-delay',index*150);
//       }
//   });
// });

        /*Gallery*/
          import Swiper, { Navigation } from 'swiper';
          const gallery_swi = new Swiper(".gallery_swipe", {
            slidesPerView: 1,
            // for screens <768 set slidesperview to 1
            breakpoints: {
              768: {
                slidesPerView: "auto",
              }
            },
            centeredSlides: false,
            navigation: {
             nextEl: '.swiper-button-next',
             prevEl: '.swiper-button-prev',
           }
          });

          /*Slider Rooms Section Homepage*/
          const rooms_swi = new Swiper(".room_swipe", {
            slidesPerView: "1",
            centeredSlides: false,
            pagination: {
              el: ".swiper-pagination",
              type: "fraction"
            },
          });
          if (rooms_swi) {
            document.querySelectorAll('.room_swipe .room-swiper-button-next').forEach(el => {
              el.addEventListener('click', () => {
                rooms_swi.slideNext();
              })
            })
            document.querySelectorAll('.room_swipe .room-swiper-button-prev').forEach(el => {
              el.addEventListener('click', () => {
                rooms_swi.slidePrev();
              })
            })
          }

          const hero_swi = new Swiper(".hero_swipe", {
            slidesPerView: "1",
            centeredSlides: false,
            autoplay: {
              delay: 5000,
            },
            loop: true,
            navigation: {
             nextEl: '.hero-swiper-button-next',
             prevEl: '.hero-swiper-button-prev',
           }
          });

// Weather widget
const dialog = document.querySelector(".weather-dialog");
const showButton = document.querySelector(".weather-dialog-button");
const closeButton = document.querySelector(".weather-dialog-close");

// "Show the dialog" button opens the dialog modally
showButton.addEventListener("click", () => {
  dialog.showModal();
});

// "Close" button closes the dialog
closeButton.addEventListener("click", () => {
  dialog.close();
});