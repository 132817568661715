var mapStyle =
[
  {
      "featureType": "administrative.country",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "administrative.land_parcel",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "landscape.natural.landcover",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "landscape.natural.terrain",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
          {
              "color": "#E57038"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "color": "#607C66"
          }
      ]
  }
];


/*id of map here*/
if ($("#map").length > 0) {
  google.maps.event.addDomListener(window, 'load', initialize_map);

}

function initialize_map() {
  var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
  var coords_dynamic = jQuery("#map").attr('data-coords');
  var splitedcoords = coords_dynamic.split(',');

  var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

  var isDraggable = true;
  var mapOptions = {
    zoom: 15,
    gestureHandling: 'cooperative',
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
     styles:mapStyle,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles:mapStyle
  };


  var map = new google.maps.Map(document.getElementById('map'),
    mapOptions);
//   var iconsource = 'http://localhost:8888/newhotelspark/wp-content/themes/hotelsparkv2/assets/images/marker_map.png';/*map pointer here*/
//   var icons = {
//     markericon: {
//       icon: iconsource
//     }
//   };

//   var marker = new google.maps.Marker({
//     position: myLatlng,
//     icon: {
// url: "http://localhost:8888/newhotelspark/wp-content/themes/hotelsparkv2/assets/images/marker_map.png"
// },
//     map: map
//   });

//   marker.setAnimation(google.maps.Animation.BOUNCE);
//   setTimeout(function(){ marker.setAnimation(null); }, 1000);

  //Map style is not mandatory
  var marker = new google.maps.Marker({
    position: myLatlng,
    map: map
  });
  var styledMap = new google.maps.StyledMapType(mapStyle, {
    name: "Styled Map"
  });
  map.mapTypes.set('mapStyle', styledMap);
  map.setMapTypeId('mapStyle')

}
